import React from 'react';
import './App.min.css';
import {Routes, Route} from "react-router-dom";
import {Container} from "semantic-ui-react";

import Home from '../Home/Home';
import NotFound from '../NotFound/NotFound';

export default function App() {
    return (
        <Container className="app-container">
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
        </Container>
    );
}