import React from 'react';
import './Home.min.css';
import {Container, Grid, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";

export default function Home() {
    return (
        <Container textAlign={"center"} className={"middle"}>
            <Grid verticalAlign={"middle"} textAlign={"center"}>
                <Grid.Row>
                    <Grid.Column className={"vhcenter"}>
                        <Image src="/img/logo.jpg" size={"medium"} centered></Image>
                        <p>Para mas información contactanos en:</p>
                        <p><Link to={"mailto:contacto@jcgcontables.com"}>contacto@jcgcontables.com</Link></p>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}