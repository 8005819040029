import React from 'react';
import './NotFound.min.css';
import {Container, Image} from "semantic-ui-react";

export default function NotFound() {
    return (
        <Container textAlign={"center"}>
            <h1>Página no encontrada</h1>
            <Image src="/img/error.gif" size={"medium"} centered></Image>
        </Container>
    );
}